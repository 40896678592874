import { ReactElement } from "react";

import sutroLogo from "~/assets/sutro-blue.svg";

import { Button } from "../ui/button";
import { LargeLoadingSpinner } from "../ui/loading-spinner";

export function FullPageLoader({
  children,
  onClick,
}: {
  children?: ReactElement;
  onClick?: () => void;
}) {
  return (
    <div
      className="flex size-full flex-col items-center justify-center gap-10 bg-[#eef6ff]"
      style={{
        backgroundImage:
          "radial-gradient(circle at center, #eef6ff 0, #ffffff 100%)",
      }}
    >
      <img src={sutroLogo} alt="Sutro" className="w-64" />
      {children}
      {onClick ? (
        <Button onClick={onClick}>Try again</Button>
      ) : (
        <LargeLoadingSpinner />
      )}
    </div>
  );
}
