import { throwError } from "@dancrumb/fpish";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  FREE_TIER_SUBSCRIPTION,
  StudioEntitlement,
  SubscriptionTier,
  UserSubscription,
} from "sutro-common/studio-entitlements";

import { FullPageLoader } from "~/components/app/full-page-loader";
import { SutroApi } from "~/lib/sutro-api";
import { either } from "~/lib/sutro-api/Api";
import {
  isStudioHttpError,
  STUDIO_HTTP_ERROR_TYPES,
  StudioHttpError,
} from "~/lib/sutro-api/StudioHttpError";
import { useProfile } from "~/lib/use-profile";

type EntitlementsContextValue = {
  entitlements: StudioEntitlement[];
  currentSubscription: UserSubscription;
  tiers: SubscriptionTier[];
  refreshEntitlements: () => Promise<void>;
};

type GetEntitlementsResponse = {
  entitlements: StudioEntitlement[];
  currentSubscription: UserSubscription;
};

const EntitlementsContext = createContext<EntitlementsContextValue>({
  currentSubscription: FREE_TIER_SUBSCRIPTION,
  tiers: [],
  entitlements: [],
  refreshEntitlements: () => Promise.resolve(),
});

/**
 * This Provider provides a list of the current user's entitlements
 */
export const EntitlementsProvider = ({ children }: PropsWithChildren) => {
  const { currentUser } = useProfile();
  const api = SutroApi.getApi().authenticate();

  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  /**
   * null means that the entitlements are still loading... we will always return an array
   */
  const [entitlements, setEntitlements] = useState<StudioEntitlement[] | null>(
    null
  );

  const [currentSubscription, setCurrentSubscription] =
    useState<UserSubscription>(FREE_TIER_SUBSCRIPTION);
  const [tiers, setTiers] = useState<SubscriptionTier[]>([]);

  const loadTiers = useCallback(
    () =>
      api
        .get<SubscriptionTier[]>("/tiers")
        .then(either<SubscriptionTier[]>(throwError).or(setTiers)),
    [api]
  );

  const updateEntitlements = useCallback(
    ({
      entitlements: newEntitlements,
      currentSubscription: newSubscription,
    }: GetEntitlementsResponse) => {
      setIsError(false);
      setEntitlements(newEntitlements);
      setCurrentSubscription(newSubscription);
    },
    []
  );

  const handleError = useCallback((e: StudioHttpError) => {
    if (!isStudioHttpError(e, STUDIO_HTTP_ERROR_TYPES.AbortedRequest)) {
      setIsError(true);
    }
  }, []);

  /**
   * Load the entitlements for the current user; if there is no current user, do nothing
   */
  const loadEntitlements = useCallback(async () => {
    if (!currentUser) {
      return Promise.resolve();
    }
    setIsError(false);

    if (api.isAuthenticated()) {
      setIsLoading(true);
      const result = await api.get<GetEntitlementsResponse>(
        "/users/current/entitlements"
      );
      setIsLoading(false);
      result.apply(handleError, updateEntitlements);
    } else {
      return Promise.resolve();
    }
  }, [api, currentUser, handleError, updateEntitlements]);

  useEffect(() => {
    if (entitlements === null && currentUser !== null) {
      void loadEntitlements();
      void loadTiers();
    }
  }, [currentUser, entitlements, loadEntitlements, loadTiers]);

  if (isError) {
    return (
      <FullPageLoader onClick={() => loadEntitlements()}>
        <h2>There was an issue connecting to our servers.</h2>
      </FullPageLoader>
    );
  } else if (isLoading) {
    return <FullPageLoader />;
  }

  return (
    <EntitlementsContext.Provider
      value={{
        currentSubscription,
        tiers,
        entitlements: entitlements ?? [],
        refreshEntitlements: loadEntitlements,
      }}
    >
      {children}
    </EntitlementsContext.Provider>
  );
};

export const useEntitlements = (): EntitlementsContextValue => {
  const { entitlements, refreshEntitlements, currentSubscription, tiers } =
    useContext(EntitlementsContext);

  return useMemo(
    () => ({
      currentSubscription,
      tiers,
      entitlements,
      refreshEntitlements,
    }),
    [currentSubscription, entitlements, refreshEntitlements, tiers]
  );
};
